import React from 'react'

export const HomepageHeader = () => {
    return (

      
        <div className="sm:flex sm:items-center justify-center py-5 border-b border-gray-200">
            
            <div className="text-center">
                <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl">Vergelijk proteïne prijzen</h1>
                <p className="mx-auto mt-3 max-w-1xl text-medium text-gray-700 sm:mt-2">
                    Vergelijk hier de proteïne poeder prijzen van de populairste aanbieders in Nederland
                </p>
            </div>
        </div>
    )
}
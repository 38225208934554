import moment from 'moment';

const LOOKBACK_IN_HOURS = process.env.NEXT_PUBLIC_LOOKBACK_IN_HOURS || 2;

export const getInStockProducts = (date) => {
    const lookbackDate = moment(date).startOf('minute').subtract(LOOKBACK_IN_HOURS, 'hours').toISOString();
    return {

        populate: ["store"],
        filters: {
            updatedAt: {
                $gte: lookbackDate,
            },
            in_stock: true,
        },
        sort: ['price_per_gram:asc', 'taste:asc']

    }
}

import { Switch } from '@headlessui/react';
import useSWR from 'swr';
import { useState } from 'react';
import { getInStockProducts } from "@/lib/products";
import { Spinner } from '@/components/spinner/spinner';
import React from 'react'
import Image from 'next/image'
import Link from 'next/link'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const Products = ({ products = [] }) => {
    const [enabled, setEnabled] = useState(true);
    const { data, isValidating } = useSWR(!enabled && { path: 'products', params: getInStockProducts() });
    const items = !enabled && data ? data.data : products;
  
    return (
        <>
            <div className="flex items-center justify-end py-2">
                <Switch.Group as="div" className="flex items-center">
                    <Switch.Label as="span" className="mr-3">
                        <span className="text-sm font-medium text-gray-900">Goedkoopste smaak per winkel</span>
                    </Switch.Label>
                    <Switch
                        checked={enabled}
                        onChange={setEnabled}
                        className={classNames(
                            enabled ? 'bg-indigo-600' : 'bg-gray-200',
                            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                        )}
                    >
                        <span
                            aria-hidden="true"
                            className={classNames(
                                enabled ? 'translate-x-5' : 'translate-x-0',
                                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                            )}
                        />
                    </Switch>
                </Switch.Group>
            </div>

            {enabled && isValidating ? (
                <div className="w-full flex align-center justify-center p-20 bg-white shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <Spinner />
                </div>
            ) : (
                <div>
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg hidden md:block">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Product
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Winkel
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Hoeveelheid
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Prijs
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Prijs per kg
                                    </th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                                {items.map((product) => (
                                    <tr key={product.id}>

                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                            <div className="flex items-center">
                                                <Link href={`/producten/${product.attributes.url_slug}-${product.id}`} >
                                                    <div className="h-20 w-20 flex-shrink-0 relative hover:cursor-pointer ">
                                                        <Image
                                                            alt={product.attributes.store.data.attributes.name}
                                                            src={product.attributes.image}
                                                            layout='fill'
                                                            objectFit="contain"
                                                        />
                                                    </div>
                                                </Link>
                                            </div>
                                        </td>

                                        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                            <div className="font-medium text-gray-900">
                                                <Link href={`/producten/${product.attributes.url_slug}-${product.id}`} >
                                                    {product.attributes.taste}
                                                </Link>
                                            </div>

                                            <div className="text-gray-500">{product.attributes.name}</div>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            {product.attributes.store.data.attributes.name}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            {product.attributes.size} gram
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            € {product.attributes.last_observed_price.toFixed(2)}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            <span className="font-medium text-gray-900">€ {(product.attributes.price_per_gram * 1000).toFixed(2)}</span> / kg
                                        </td>
                                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                            <Link href={`/producten/${product.attributes.url_slug}-${product.id}`} ><a className="rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30">Bekijk</a></Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="md:hidden">
                        {items.map((product) => (


                            <div key={product.id} className="bg-white last:rounded-b-lg first:rounded-t-lg shadow p-2">

                                <div className="flex max-w-full">
                                    <Link href={`/producten/${product.attributes.url_slug}-${product.id}`} >

                                        <div className="flex items-center">
                                            <div className="h-16 w-16 relative">
                                                <Image
                                                    alt={product.attributes.store.data.attributes.name}
                                                    src={product.attributes.image}
                                                    layout='fill'
                                                    objectFit="contain"
                                                />
                                            </div>
                                        </div>
                                    </Link>

                                    <div className="grow w-12 pl-4">
                                        <div className="font-medium text-gray-900 whitespace-nowrap truncate">{product.attributes.taste}</div>
                                        <div className="whitespace-nowrap text-gray-500 text-left text-clip">{product.attributes.store.data.attributes.name}</div>
                                        <div className="flex items-center gap-2">
                                            <div className="whitespace-nowrap text-sm"> {product.attributes.size} gr.</div>
                                            <div className="whitespace-nowrap text-sm">€ {product.attributes.last_observed_price.toFixed(2)}</div>
                                        </div>
                                    </div>
                                    <div className="flex-none">
                                        <div className="flex flex-col items-end text-sm">

                                            <div className="mt-1">
                                                <span className="text-sm font-bold text-gray-900">€ {(product.attributes.price_per_gram * 1000).toFixed(2)} </span>/ kg
                                            </div>

                                            <div className="mt-4">
                                                <Link href={`/producten/${product.attributes.url_slug}-${product.id}`} ><a className="rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30">Bekijk</a></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        ))}
                    </div>
                </div>
            )
            }
        </>
    )
}

import DefaultLayout from '../layouts/default';
import { fetchAPI } from '../lib/strapiApi';
import { HomepageHeader } from '../components/homepageHeader';
import { Products } from '../components/products/productList';
import { getInStockProductsForStore } from '../lib/stores';
import { PageHead } from '../components/pageHead'


const getFirstProductFromStore = (store) => {
    const product = store.attributes.products.data[0];
    return {
        id: product.id,
        attributes: {
            ...product.attributes,
            store: {
                data: {
                    id: store.id,
                    attributes: {
                        name: store.attributes.name
                    }
                }
            }
        }
    }
};

const filterStoreWithNoProducts = (store) => {
    return Object.keys(store?.attributes?.products?.data).length == 0 ? false : true
}

const sortStoresByProductPrice = (a, b) => a.attributes.price_per_gram - b.attributes.price_per_gram;


const HomePage = ({ products }) => {
    return (
        <div>
            <PageHead title='Proteïne prijzen vergelijken' description='Vergelijk hier de proteïne poeder prijzen van de populairste aanbieders in Nederland' />
            <DefaultLayout>
                <HomepageHeader />
                <Products products={products} />
            </DefaultLayout>
        </div>
    )
}

export async function getServerSideProps(ctx) {
    const { data: productsAll = [] } = await fetchAPI({ path: 'stores', params: await getInStockProductsForStore() })
    const products = productsAll.filter(filterStoreWithNoProducts).map(getFirstProductFromStore).sort(sortStoresByProductPrice);
    return {
        props: {
            products
        }
    }
}

export default HomePage;

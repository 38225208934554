import React from 'react'
import Head from 'next/head'


export const PageHead = ({ title, description }) => {
    const finalTitle = `${title} | Whey-Shakes.nl`
    return (
        <Head>
            <title>{finalTitle}</title>
            <meta
                name="description"
                content={description}
            />
        </Head>
    )
}